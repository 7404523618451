
html,
body {
  height: 'auto';
  min-height: 100%;
  min-width: 360px;
}
body {
  margin: 0;
  font-family: "omnes-pro",  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
